import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, UntypedFormGroup } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Constant } from 'src/app/constants/constant';
import { CountryLocationService } from 'src/app/services/country-location.service';
import { FieldConfig } from '../field.interface';

import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

import * as lpn from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

@Component({
  selector: 'calsoft-phone-no',
  templateUrl: './phone-no.component.html',
  styleUrls: ['./phone-no.component.scss']
})
export class PhoneNoComponent implements OnInit {
  event: any;
  pinform: FormGroup;
  //filteredCountries: any[];
  searchControl = new FormControl();
  validationErr:boolean;


  @Input() field: FieldConfig;
  @Input() customField13: any = 0;
  @Input() group: UntypedFormGroup;
  @Input() type: any;
  @Output() valueChange = new EventEmitter<any>();
  inputLength: number = 0;
  display: boolean = false;
  resultvalue: boolean = false;


  
  originalCountries: { id: string, name: string, flag:string }[] = [
    {
        "id": "1",
        "name": "Afghanistan +93",
        "flag": "https://flagcdn.com/16x12/af.png"
    },
    {
        "id": "2",
        "name": "Albania +355",
        "flag": "https://flagcdn.com/16x12/al.png"
    },
    {
        "id": "3",
        "name": "Algeria +213",
        "flag": "https://flagcdn.com/16x12/dz.png"
    },
    {
        "id": "4",
        "name": "Andorra +376",
        "flag": "https://flagcdn.com/16x12/ad.png"
    },
    {
        "id": "5",
        "name": "Angola +244",
        "flag": "https://flagcdn.com/16x12/ao.png"
    },
    {
        "id": "6",
        "name": "Argentina +54",
        "flag": "https://flagcdn.com/16x12/ar.png"
    },
    {
        "id": "7",
        "name": "Armenia +374",
        "flag": "https://flagcdn.com/16x12/am.png"
    },
    {
        "id": "8",
        "name": "Australia +61",
        "flag": "https://flagcdn.com/16x12/au.png"
    },
    {
        "id": "9",
        "name": "Austria +43",
        "flag": "https://flagcdn.com/16x12/at.png"
    },
    {
        "id": "10",
        "name": "Azerbaijan +994",
        "flag": "https://flagcdn.com/16x12/az.png"
    },
    {
        "id": "11",
        "name": "Bahamas +1-242",
        "flag": "https://flagcdn.com/16x12/bs.png"
    },
    {
        "id": "12",
        "name": "Bahrain +973",
        "flag": "https://flagcdn.com/16x12/bh.png"
    },
    {
        "id": "13",
        "name": "Bangladesh +880",
        "flag": "https://flagcdn.com/16x12/bd.png"
    },
    {
        "id": "14",
        "name": "Barbados +1-246",
        "flag": "https://flagcdn.com/16x12/bb.png"
    },
    {
        "id": "15",
        "name": "Belarus +375",
        "flag": "https://flagcdn.com/16x12/by.png"
    },
    {
        "id": "16",
        "name": "Belgium +32",
        "flag": "https://flagcdn.com/16x12/be.png"
    },
    {
        "id": "17",
        "name": "Belize +501",
        "flag": "https://flagcdn.com/16x12/bz.png"
    },
    {
        "id": "18",
        "name": "Benin +229",
        "flag": "https://flagcdn.com/16x12/bj.png"
    },
    {
        "id": "19",
        "name": "Bhutan +975",
        "flag": "https://flagcdn.com/16x12/bt.png"
    },
    {
        "id": "20",
        "name": "Bolivia +591",
        "flag": "https://flagcdn.com/16x12/bo.png"
    },
    {
        "id": "21",
        "name": "Bosnia and Herzegovina +387",
        "flag": "https://flagcdn.com/16x12/ba.png"
    },
    {
        "id": "22",
        "name": "Botswana +267",
        "flag": "https://flagcdn.com/16x12/bw.png"
    },
    {
        "id": "23",
        "name": "Brazil +55",
        "flag": "https://flagcdn.com/16x12/br.png"
    },
    {
        "id": "24",
        "name": "Brunei +673",
        "flag": "https://flagcdn.com/16x12/bn.png"
    },
    {
        "id": "25",
        "name": "Bulgaria +359",
        "flag": "https://flagcdn.com/16x12/bg.png"
    },
    {
        "id": "26",
        "name": "Burkina Faso +226",
        "flag": "https://flagcdn.com/16x12/bf.png"
    },
    {
        "id": "27",
        "name": "Burundi +257",
        "flag": "https://flagcdn.com/16x12/bi.png"
    },
    {
        "id": "28",
        "name": "Cambodia +855",
        "flag": "https://flagcdn.com/16x12/kh.png"
    },
    {
        "id": "29",
        "name": "Cameroon +237",
        "flag": "https://flagcdn.com/16x12/cm.png"
    },
    {
        "id": "30",
        "name": "Canada +1",
        "flag": "https://flagcdn.com/16x12/ca.png"
    },
    {
        "id": "31",
        "name": "Cape Verde +238",
        "flag": "https://flagcdn.com/16x12/cv.png"
    },
    {
        "id": "32",
        "name": "Central African Republic +236",
        "flag": "https://flagcdn.com/16x12/cf.png"
    },
    {
        "id": "33",
        "name": "Chad +235",
        "flag": "https://flagcdn.com/16x12/td.png"
    },
    {
        "id": "34",
        "name": "Chile +56",
        "flag": "https://flagcdn.com/16x12/cl.png"
    },
    {
        "id": "35",
        "name": "China +86",
        "flag": "https://flagcdn.com/16x12/cn.png"
    },
    {
        "id": "36",
        "name": "Colombia +57",
        "flag": "https://flagcdn.com/16x12/co.png"
    },
    {
        "id": "37",
        "name": "Comoros +269",
        "flag": "https://flagcdn.com/16x12/km.png"
    },
    {
        "id": "38",
        "name": "Congo +242",
        "flag": "https://flagcdn.com/16x12/cg.png"
    },
    {
        "id": "39",
        "name": "Congo, Democratic Republic of the +243",
        "flag": "https://flagcdn.com/16x12/cd.png"
    },
    {
        "id": "40",
        "name": "Costa Rica +506",
        "flag": "https://flagcdn.com/16x12/cr.png"
    },
    {
        "id": "41",
        "name": "Croatia +385",
        "flag": "https://flagcdn.com/16x12/hr.png"
    },
    {
        "id": "42",
        "name": "Cuba +53",
        "flag": "https://flagcdn.com/16x12/cu.png"
    },
    {
        "id": "43",
        "name": "Cyprus +357",
        "flag": "https://flagcdn.com/16x12/cy.png"
    },
    {
        "id": "44",
        "name": "Czech Republic +420",
        "flag": "https://flagcdn.com/16x12/cz.png"
    },
    {
        "id": "45",
        "name": "Denmark +45",
        "flag": "https://flagcdn.com/16x12/dk.png"
    },
    {
        "id": "46",
        "name": "Djibouti +253",
        "flag": "https://flagcdn.com/16x12/dj.png"
    },
    {
        "id": "47",
        "name": "Dominica +1-767",
        "flag": "https://flagcdn.com/16x12/dm.png"
    },
    {
        "id": "48",
        "name": "Dominican Republic +1-809",
        "flag": "https://flagcdn.com/16x12/do.png"
    },
    {
        "id": "49",
        "name": "Ecuador +593",
        "flag": "https://flagcdn.com/16x12/ec.png"
    },
    {
        "id": "50",
        "name": "Egypt +20",
        "flag": "https://flagcdn.com/16x12/eg.png"
    },
    {
        "id": "51",
        "name": "El Salvador +503",
        "flag": "https://flagcdn.com/16x12/sv.png"
    },
    {
        "id": "52",
        "name": "Equatorial Guinea +240",
        "flag": "https://flagcdn.com/16x12/gq.png"
    },
    {
        "id": "53",
        "name": "Eritrea +291",
        "flag": "https://flagcdn.com/16x12/er.png"
    },
    {
        "id": "54",
        "name": "Estonia +372",
        "flag": "https://flagcdn.com/16x12/ee.png"
    },
    {
        "id": "55",
        "name": "Eswatini +268",
        "flag": "https://flagcdn.com/16x12/sz.png"
    },
    {
        "id": "56",
        "name": "Ethiopia +251",
        "flag": "https://flagcdn.com/16x12/et.png"
    },
    {
        "id": "57",
        "name": "Fiji +679",
        "flag": "https://flagcdn.com/16x12/fj.png"
    },
    {
        "id": "58",
        "name": "Finland +358",
        "flag": "https://flagcdn.com/16x12/fi.png"
    },
    {
        "id": "59",
        "name": "France +33",
        "flag": "https://flagcdn.com/16x12/fr.png"
    },
    {
        "id": "60",
        "name": "Gabon +241",
        "flag": "https://flagcdn.com/16x12/ga.png"
    },
    {
        "id": "61",
        "name": "Gambia +220",
        "flag": "https://flagcdn.com/16x12/gm.png"
    },
    {
        "id": "62",
        "name": "Georgia +995",
        "flag": "https://flagcdn.com/16x12/ge.png"
    },
    {
        "id": "63",
        "name": "Germany +49",
        "flag": "https://flagcdn.com/16x12/de.png"
    },
    {
        "id": "64",
        "name": "Ghana +233",
        "flag": "https://flagcdn.com/16x12/gh.png"
    },
    {
        "id": "65",
        "name": "Greece +30",
        "flag": "https://flagcdn.com/16x12/gr.png"
    },
    {
        "id": "66",
        "name": "Grenada +1-473",
        "flag": "https://flagcdn.com/16x12/gd.png"
    },
    {
        "id": "67",
        "name": "Guatemala +502",
        "flag": "https://flagcdn.com/16x12/gt.png"
    },
    {
        "id": "68",
        "name": "Guinea +224",
        "flag": "https://flagcdn.com/16x12/gn.png"
    },
    {
        "id": "69",
        "name": "Guinea-Bissau +245",
        "flag": "https://flagcdn.com/16x12/gw.png"
    },
    {
        "id": "70",
        "name": "Guyana +592",
        "flag": "https://flagcdn.com/16x12/gy.png"
    },
    {
        "id": "71",
        "name": "Haiti +509",
        "flag": "https://flagcdn.com/16x12/ht.png"
    },
    {
        "id": "72",
        "name": "Honduras +504",
        "flag": "https://flagcdn.com/16x12/hn.png"
    },
    {
        "id": "73",
        "name": "Hungary +36",
        "flag": "https://flagcdn.com/16x12/hu.png"
    },
    {
        "id": "74",
        "name": "Iceland +354",
        "flag": "https://flagcdn.com/16x12/is.png"
    },
    {
        "id": "75",
        "name": "India +91",
        "flag": "https://flagcdn.com/16x12/in.png"
    },
    {
        "id": "76",
        "name": "Indonesia +62",
        "flag": "https://flagcdn.com/16x12/id.png"
    },
    {
        "id": "77",
        "name": "Iran +98",
        "flag": "https://flagcdn.com/16x12/ir.png"
    },
    {
        "id": "78",
        "name": "Iraq +964",
        "flag": "https://flagcdn.com/16x12/iq.png"
    },
    {
        "id": "79",
        "name": "Ireland +353",
        "flag": "https://flagcdn.com/16x12/ie.png"
    },
    {
        "id": "80",
        "name": "Israel +972",
        "flag": "https://flagcdn.com/16x12/il.png"
    },
    {
        "id": "81",
        "name": "Italy +39",
        "flag": "https://flagcdn.com/16x12/it.png"
    },
    {
        "id": "82",
        "name": "Jamaica +1-876",
        "flag": "https://flagcdn.com/16x12/jm.png"
    },
    {
        "id": "83",
        "name": "Japan +81",
        "flag": "https://flagcdn.com/16x12/jp.png"
    },
    {
        "id": "84",
        "name": "Jordan +962",
        "flag": "https://flagcdn.com/16x12/jo.png"
    },
    {
        "id": "85",
        "name": "Kazakhstan +7",
        "flag": "https://flagcdn.com/16x12/kz.png"
    },
    {
        "id": "86",
        "name": "Kenya +254",
        "flag": "https://flagcdn.com/16x12/ke.png"
    },
    {
        "id": "87",
        "name": "Kiribati +686",
        "flag": "https://flagcdn.com/16x12/ki.png"
    },
    {
        "id": "88",
        "name": "Korea, North +850",
        "flag": "https://flagcdn.com/16x12/kp.png"
    },
    {
        "id": "89",
        "name": "Korea, South +82",
        "flag": "https://flagcdn.com/16x12/kr.png"
    },
    {
        "id": "90",
        "name": "Kuwait +965",
        "flag": "https://flagcdn.com/16x12/kw.png"
    },
    {
        "id": "91",
        "name": "Kyrgyzstan +996",
        "flag": "https://flagcdn.com/16x12/kg.png"
    },
    {
        "id": "92",
        "name": "Laos +856",
        "flag": "https://flagcdn.com/16x12/la.png"
    },
    {
        "id": "93",
        "name": "Latvia +371",
        "flag": "https://flagcdn.com/16x12/lv.png"
    },
    {
        "id": "94",
        "name": "Lebanon +961",
        "flag": "https://flagcdn.com/16x12/lb.png"
    },
    {
        "id": "95",
        "name": "Lesotho +266",
        "flag": "https://flagcdn.com/16x12/ls.png"
    },
    {
        "id": "96",
        "name": "Liberia +231",
        "flag": "https://flagcdn.com/16x12/lr.png"
    },
    {
        "id": "97",
        "name": "Libya +218",
        "flag": "https://flagcdn.com/16x12/ly.png"
    },
    {
        "id": "98",
        "name": "Liechtenstein +423",
        "flag": "https://flagcdn.com/16x12/li.png"
    },
    {
        "id": "99",
        "name": "Lithuania +370",
        "flag": "https://flagcdn.com/16x12/lt.png"
    },
    {
        "id": "100",
        "name": "Luxembourg +352",
        "flag": "https://flagcdn.com/16x12/lu.png"
    },
    {
        "id": "101",
        "name": "Madagascar +261",
        "flag": "https://flagcdn.com/16x12/mg.png"
    },
    {
        "id": "102",
        "name": "Malawi +265",
        "flag": "https://flagcdn.com/16x12/mw.png"
    },
    {
        "id": "103",
        "name": "Malaysia +60",
        "flag": "https://flagcdn.com/16x12/my.png"
    },
    {
        "id": "104",
        "name": "Maldives +960",
        "flag": "https://flagcdn.com/16x12/mv.png"
    },
    {
        "id": "105",
        "name": "Mali +223",
        "flag": "https://flagcdn.com/16x12/ml.png"
    },
    {
        "id": "106",
        "name": "Malta +356",
        "flag": "https://flagcdn.com/16x12/mt.png"
    },
    {
        "id": "107",
        "name": "Marshall Islands +692",
        "flag": "https://flagcdn.com/16x12/mh.png"
    },
    {
        "id": "108",
        "name": "Mauritania +222",
        "flag": "https://flagcdn.com/16x12/mr.png"
    },
    {
        "id": "109",
        "name": "Mauritius +230",
        "flag": "https://flagcdn.com/16x12/mu.png"
    },
    {
        "id": "110",
        "name": "Mexico +52",
        "flag": "https://flagcdn.com/16x12/mx.png"
    },
    {
        "id": "111",
        "name": "Micronesia +691",
        "flag": "https://flagcdn.com/16x12/fm.png"
    },
    {
        "id": "112",
        "name": "Moldova +373",
        "flag": "https://flagcdn.com/16x12/md.png"
    },
    {
        "id": "113",
        "name": "Monaco +377",
        "flag": "https://flagcdn.com/16x12/mc.png"
    },
    {
        "id": "114",
        "name": "Mongolia +976",
        "flag": "https://flagcdn.com/16x12/mn.png"
    },
    {
        "id": "115",
        "name": "Montenegro +382",
        "flag": "https://flagcdn.com/16x12/me.png"
    },
    {
        "id": "116",
        "name": "Morocco +212",
        "flag": "https://flagcdn.com/16x12/ma.png"
    },
    {
        "id": "117",
        "name": "Mozambique +258",
        "flag": "https://flagcdn.com/16x12/mz.png"
    },
    {
        "id": "118",
        "name": "Myanmar +95",
        "flag": "https://flagcdn.com/16x12/mm.png"
    },
    {
        "id": "119",
        "name": "Namibia +264",
        "flag": "https://flagcdn.com/16x12/na.png"
    },
    {
        "id": "120",
        "name": "Nauru +674",
        "flag": "https://flagcdn.com/16x12/nr.png"
    },
    {
        "id": "121",
        "name": "Nepal +977",
        "flag": "https://flagcdn.com/16x12/np.png"
    },
    {
        "id": "122",
        "name": "Netherlands +31",
        "flag": "https://flagcdn.com/16x12/nl.png"
    },
    {
        "id": "123",
        "name": "New Zealand +64",
        "flag": "https://flagcdn.com/16x12/nz.png"
    },
    {
        "id": "124",
        "name": "Nicaragua +505",
        "flag": "https://flagcdn.com/16x12/ni.png"
    },
    {
        "id": "125",
        "name": "Niger +227",
        "flag": "https://flagcdn.com/16x12/ne.png"
    },
    {
        "id": "126",
        "name": "Nigeria +234",
        "flag": "https://flagcdn.com/16x12/ng.png"
    },
    {
        "id": "127",
        "name": "North Macedonia +389",
        "flag": "https://flagcdn.com/16x12/mk.png"
    },
    {
        "id": "128",
        "name": "Norway +47",
        "flag": "https://flagcdn.com/16x12/no.png"
    },
    {
        "id": "129",
        "name": "Oman +968",
        "flag": "https://flagcdn.com/16x12/om.png"
    },
    {
        "id": "130",
        "name": "Pakistan +92",
        "flag": "https://flagcdn.com/16x12/pk.png"
    },
    {
        "id": "131",
        "name": "Palau +680",
        "flag": "https://flagcdn.com/16x12/pw.png"
    },
    {
        "id": "132",
        "name": "Panama +507",
        "flag": "https://flagcdn.com/16x12/pa.png"
    },
    {
        "id": "133",
        "name": "Papua New Guinea +675",
        "flag": "https://flagcdn.com/16x12/pg.png"
    },
    {
        "id": "134",
        "name": "Paraguay +595",
        "flag": "https://flagcdn.com/16x12/py.png"
    },
    {
        "id": "135",
        "name": "Peru +51",
        "flag": "https://flagcdn.com/16x12/pe.png"
    },
    {
        "id": "136",
        "name": "Philippines +63",
        "flag": "https://flagcdn.com/16x12/ph.png"
    },
    {
        "id": "137",
        "name": "Poland +48",
        "flag": "https://flagcdn.com/16x12/pl.png"
    },
    {
        "id": "138",
        "name": "Portugal +351",
        "flag": "https://flagcdn.com/16x12/pt.png"
    },
    {
        "id": "139",
        "name": "Qatar +974",
        "flag": "https://flagcdn.com/16x12/qa.png"
    },
    {
        "id": "140",
        "name": "Romania +40",
        "flag": "https://flagcdn.com/16x12/ro.png"
    },
    {
        "id": "141",
        "name": "Russia +7",
        "flag": "https://flagcdn.com/16x12/ru.png"
    },
    {
        "id": "142",
        "name": "Rwanda +250",
        "flag": "https://flagcdn.com/16x12/rw.png"
    },
    {
        "id": "143",
        "name": "Saint Kitts and Nevis +1-869",
        "flag": "https://flagcdn.com/16x12/kn.png"
    },
    {
        "id": "144",
        "name": "Saint Lucia +1-758",
        "flag": "https://flagcdn.com/16x12/lc.png"
    },
    {
        "id": "145",
        "name": "Saint Vincent and the Grenadines +1-784",
        "flag": "https://flagcdn.com/16x12/vc.png"
    },
    {
        "id": "146",
        "name": "Samoa +685",
        "flag": "https://flagcdn.com/16x12/ws.png"
    },
    {
        "id": "147",
        "name": "San Marino +378",
        "flag": "https://flagcdn.com/16x12/sm.png"
    },
    {
        "id": "148",
        "name": "Sao Tome and Principe +239",
        "flag": "https://flagcdn.com/16x12/st.png"
    },
    {
        "id": "149",
        "name": "Saudi Arabia +966",
        "flag": "https://flagcdn.com/16x12/sa.png"
    },
    {
        "id": "150",
        "name": "Senegal +221",
        "flag": "https://flagcdn.com/16x12/sn.png"
    },
    {
        "id": "151",
        "name": "Serbia +381",
        "flag": "https://flagcdn.com/16x12/rs.png"
    },
    {
        "id": "152",
        "name": "Seychelles +248",
        "flag": "https://flagcdn.com/16x12/sc.png"
    },
    {
        "id": "153",
        "name": "Sierra Leone +232",
        "flag": "https://flagcdn.com/16x12/sl.png"
    },
    {
        "id": "154",
        "name": "Singapore +65",
        "flag": "https://flagcdn.com/16x12/sg.png"
    },
    {
        "id": "155",
        "name": "Slovakia +421",
        "flag": "https://flagcdn.com/16x12/sk.png"
    },
    {
        "id": "156",
        "name": "Slovenia +386",
        "flag": "https://flagcdn.com/16x12/si.png"
    },
    {
        "id": "157",
        "name": "Solomon Islands +677",
        "flag": "https://flagcdn.com/16x12/sb.png"
    },
    {
        "id": "158",
        "name": "Somalia +252",
        "flag": "https://flagcdn.com/16x12/so.png"
    },
    {
        "id": "159",
        "name": "South Africa +27",
        "flag": "https://flagcdn.com/16x12/za.png"
    },
    {
        "id": "160",
        "name": "South Korea +82",
        "flag": "https://flagcdn.com/16x12/kr.png"
    },
    {
        "id": "161",
        "name": "South Sudan +211",
        "flag": "https://flagcdn.com/16x12/ss.png"
    },
    {
        "id": "162",
        "name": "Spain +34",
        "flag": "https://flagcdn.com/16x12/es.png"
    },
    {
        "id": "163",
        "name": "Sri Lanka +94",
        "flag": "https://flagcdn.com/16x12/lk.png"
    },
    {
        "id": "164",
        "name": "Sudan +249",
        "flag": "https://flagcdn.com/16x12/sd.png"
    },
    {
        "id": "165",
        "name": "Suriname +597",
        "flag": "https://flagcdn.com/16x12/sr.png"
    },
    {
        "id": "166",
        "name": "Sweden +46",
        "flag": "https://flagcdn.com/16x12/se.png"
    },
    {
        "id": "167",
        "name": "Switzerland +41",
        "flag": "https://flagcdn.com/16x12/ch.png"
    },
    {
        "id": "168",
        "name": "Syria +963",
        "flag": "https://flagcdn.com/16x12/sy.png"
    },
    {
        "id": "169",
        "name": "Taiwan +886",
        "flag": "https://flagcdn.com/16x12/tw.png"
    },
    {
        "id": "170",
        "name": "Tajikistan +992",
        "flag": "https://flagcdn.com/16x12/tj.png"
    },
    {
        "id": "171",
        "name": "Tanzania +255",
        "flag": "https://flagcdn.com/16x12/tz.png"
    },
    {
        "id": "172",
        "name": "Thailand +66",
        "flag": "https://flagcdn.com/16x12/th.png"
    },
    {
        "id": "173",
        "name": "Timor-Leste +670",
        "flag": "https://flagcdn.com/16x12/tl.png"
    },
    {
        "id": "174",
        "name": "Togo +228",
        "flag": "https://flagcdn.com/16x12/tg.png"
    },
    {
        "id": "175",
        "name": "Tonga +676",
        "flag": "https://flagcdn.com/16x12/to.png"
    },
    {
        "id": "176",
        "name": "Trinidad and Tobago +1-868",
        "flag": "https://flagcdn.com/16x12/tt.png"
    },
    {
        "id": "177",
        "name": "Tunisia +216",
        "flag": "https://flagcdn.com/16x12/tn.png"
    },
    {
        "id": "178",
        "name": "Turkey +90",
        "flag": "https://flagcdn.com/16x12/tr.png"
    },
    {
        "id": "179",
        "name": "Turkmenistan +993",
        "flag": "https://flagcdn.com/16x12/tm.png"
    },
    {
        "id": "180",
        "name": "Tuvalu +688",
        "flag": "https://flagcdn.com/16x12/tv.png"
    },
    {
        "id": "181",
        "name": "Uganda +256",
        "flag": "https://flagcdn.com/16x12/ug.png"
    },
    {
        "id": "182",
        "name": "Ukraine +380",
        "flag": "https://flagcdn.com/16x12/ua.png"
    },
    {
        "id": "183",
        "name": "United Arab Emirates +971",
        "flag": "https://flagcdn.com/16x12/ae.png"
    },
    {
        "id": "184",
        "name": "United Kingdom +44",
        "flag": "https://flagcdn.com/16x12/gb.png"
    },
    {
        "id": "185",
        "name": "United States +1",
        "flag": "https://flagcdn.com/16x12/us.png"
    },
    {
        "id": "186",
        "name": "Uruguay +598",
        "flag": "https://flagcdn.com/16x12/uy.png"
    },
    {
        "id": "187",
        "name": "Uzbekistan +998",
        "flag": "https://flagcdn.com/16x12/uz.png"
    },
    {
        "id": "188",
        "name": "Vanuatu +678",
        "flag": "https://flagcdn.com/16x12/vu.png"
    },
    {
        "id": "189",
        "name": "Vatican City +379",
        "flag": "https://flagcdn.com/16x12/va.png"
    },
    {
        "id": "190",
        "name": "Venezuela +58",
        "flag": "https://flagcdn.com/16x12/ve.png"
    },
    {
        "id": "191",
        "name": "Vietnam +84",
        "flag": "https://flagcdn.com/16x12/vn.png"
    },
    {
        "id": "192",
        "name": "Yemen +967",
        "flag": "https://flagcdn.com/16x12/ye.png"
    },
    {
        "id": "193",
        "name": "Zambia +260",
        "flag": "https://flagcdn.com/16x12/zm.png"
    },
    {
        "id": "194",
        "name": "Zimbabwe +263",
        "flag": "https://flagcdn.com/16x12/zw.png"
    }
]
  filteredCountries: { id: string, name: string, flag:string}[] = [];
  MobmaxLength: any;
  invalidNumber: boolean=false;
  countryCode: any;

  constructor(private fb: FormBuilder, private sanitizer: DomSanitizer,
    private countryLocationService:CountryLocationService) {
    this.validationErr = false;
    this.MobmaxLength = 10;
  }
 
  ngOnInit(): void {


   
    this.pinform = this.fb.group({
      endTimeHour: ['', [Validators.required]],
      ampm: ['75', Validators.required],
      flag:[]
    });

    let countryData=localStorage.getItem(Constant.COUNTRY_LOCATION);
 
    if(countryData==null || countryData==undefined ){
      this.getUserLocationData();
    }else{
      this.countryCode=JSON.parse(countryData);
      this.setCountryData()
    }


 // Initialize filteredCountries with the original list
    this.filteredCountries = this.originalCountries;

    this.searchControl.valueChanges.subscribe(value => {
      this.filterCountries(value);
    });


    const control = this.group.get(this.field.name);
    if (control) {
      control.valueChanges.subscribe(value => {
        this.inputLength = value?.length;
     //   this.checkCondition();
      });
    }

    if (this.group != undefined) {
      this.display = true;
    }

  }

  setCountryData(){

    //India +91

    let country=this.countryCode['country_name']
    let code =this.countryCode['country_calling_code']

    let value = country + " "+code;

    const recursiveIndex = this.originalCountries.findIndex(recursiveItem => recursiveItem.name == value);

    if (recursiveIndex !== -1) {

      
     
      this.pinform.get("ampm").setValue(this.originalCountries[recursiveIndex].id);

      this.pinform.get("flag").setValue(this.originalCountries[recursiveIndex].flag);

    
      
    }else{
      this.pinform.get("ampm").setValue("75");

      this.pinform.get("flag").setValue("https://flagcdn.com/16x12/in.png");

    }
    
  }

  getUserLocationData() {

  
     
    this.countryCode=this.countryLocationService.getCountryCode()
   if(this.countryCode!=undefined){
    this.setCountryData()
   }
    
  }

  get endTimeHour() {
    return this.pinform.get('endTimeHour');
  }

  getFlagUrl(code: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(code);
  }

  isValidPhoneNumber: boolean = false; 

  onSelectionChange(event: any) {
    const selectedValue = event.value;

    this.pinform.get('endTimeHour').reset();
    this.pinform.get('endTimeHour').setValidators([Validators.required]);
    this.pinform.get('endTimeHour').updateValueAndValidity();

  }

  getCountryCode(value: string) {

    let index = this.originalCountries.findIndex(x => x.id == this.pinform.value.ampm);

    if(index>-1){
      


      this.countryCode = this.extractCountryCode(this.originalCountries[index].name);

  
      return this.countryCode;
      



    }
    
  }

  extractCountryCode(text: string): string | null {
    const match = text.match(/\+\d+/);
    return match ? match[0] : null;
  }


 filterCountries(value: string): void {
    const filterValue = value.toLowerCase();
    if (!filterValue) {
      // If the search input is empty, display the original list
      this.filteredCountries = this.originalCountries.slice();
    } else {
      // Filter the countries based on the search input
      this.filteredCountries = this.originalCountries.filter(country =>
        country.name.toLowerCase().includes(filterValue)
      );
    }
  }

  preventMatSelectOpen(event: Event) {
    event.stopPropagation();
  }


  onSubmit() {
    // console.log(this.pinform.value);
    // console.log(this.getCountryCode(this.pinform.get('ampm').value));
    let params : any = {
      phone:this.pinform.value.endTimeHour,
      id:this.pinform.value.ampm,
      code:this.getCountryCode(this.pinform.get('ampm').value)
      }
    
  }
  
  validateIndianPhoneNumber(value: string) {
    const indianPhoneNumberPattern = /^[6789]\d{9}$/;
    if (!indianPhoneNumberPattern.test(value)) {
      return true;
    } else {
      return false;
    }
  }

  validate_PhoneNumber(value: string) {
    const indianPhoneNumberPattern =  /^[1234567890]\d*$/;
    if (!indianPhoneNumberPattern.test(value)) {
     
      this.invalidNumber=true;
    
    } else {
     
      this.invalidNumber=false;
    }
  }

  errmsg(event){
    const mobile = this.pinform.get('endTimeHour').value;
    const selectedCountry = this.pinform.get('ampm').value;
  
    let validationResult: any = null;
  
    switch (selectedCountry) {
      case '75': // India
        validationResult = this.validateIndianPhoneNumber(mobile);
        break;
      case '1': // Afghanistan
        validationResult = this.validate_PhoneNumber(mobile);
        break;
      default:
        validationResult = this.validate_PhoneNumber(mobile);
        break;
    }
    const endTimeHourControl = this.pinform.get('endTimeHour');

    const teamControl = this.group.get(this.field.name);

    if (mobile.length !== this.MobmaxLength) {
      validationResult = true;
    }
  
    if (validationResult) {
      this.validationErr = true;
   
      this.pinform.get('endTimeHour').setErrors({ customError: true });
    } else {
      this.validationErr = false;
      // No validation error, proceed with form submission
      console.log('Valid number');
      this.pinform.get('endTimeHour').setErrors({ customError: false });
  console.log(this.pinform);
  
    }
  }

  isSubmitEnabled(): boolean {
    if (
        !this.pinform.get('endTimeHour').errors?.required &&
        this.MobmaxLength === this.pinform.get('endTimeHour').value.length &&
        !this.pinform.get('endTimeHour').errors?.maxlength &&
        !this.validationErr
    ) {
        return true; // Enable submit button
    } else {
        return false; // Disable submit button
    }
}


changeValue(value: string, text: string) {
  const endTimeHourControl = this.pinform.get('endTimeHour');
  const teamControl = this.group.get(this.field.name);

  // Update the form control value directly
  this.pinform.patchValue({ [value]: text });

  const index = this.originalCountries.findIndex(x => x.id === this.pinform.value.ampm);

  if (index > -1) {
      const url = this.originalCountries[index].flag;
      const match = url.match(/\/([a-z]{2})\.png$/);
      const countryCode = match ? match[1].toUpperCase() : null;

              try {
                
                  // const number = this.phoneUtil.parse(text, countryCode);

                  const number = phoneUtil.parse(text, countryCode);


                  const isValid = phoneUtil.isValidNumberForRegion(number, countryCode);
            
                 // Final validation check
  if (!isValid) {
    setTimeout(() => {
    this.pinform.get('endTimeHour').setErrors({ customError: 'Invalid phone number' });
   console.log("false");
  }, 500); // 500ms delay
 
   
} else {
  setTimeout(() => {
    this.pinform.get('endTimeHour').setErrors(null);
    console.log("Phone number is valid (cleared after delay).");
    console.log(teamControl);
  }, 500); // 500ms delay
    

   let sa=number.values_['1']
   let sa1=text;

   
  this.group.get(this.field.name).setValue(sa+sa1);
}
              } catch (error) {
                  console.error("Error parsing phone number:", error);
                  this.isValidPhoneNumber = false;
              }
          
     
  }

  
}




ngOnChanges(changes: SimpleChanges): void {
  if (changes.field && changes.field.currentValue) {
    const control = this.group.get(this.field.name);
    if (control) {
      control.valueChanges.subscribe(value => {
        this.inputLength = value?.length;
      });
    }
  }
}


isRequired(fieldName: string): boolean {
  const control = this.group.get(fieldName);
  return control && control.hasError('required');
}



setValue(value: any) {
  if (this.group.get(this.field.name)?.valid) {
    this.group.get(this.field.name).setValue(value);
    this.valueChange.emit( this.field.name);
  } else {
    console.error('Form control is invalid');
  }
}

}

