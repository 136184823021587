<mat-toolbar class="mobileheader">
    <mat-toolbar-row [style.margin]="mobileHeaderLogoGap" [style.height]="mobileHeaderHeight" fxLayout="row"
        fxLayoutAlign="space-between center">
        <section fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">

            <div *ngIf="layout==0&&mobileLogoEnable==0" fxLayoutAlign="start center">
                <calsoft-logo [sitename]="data.siteName" fxFlex [type]="'light'"></calsoft-logo>
            </div>

            <div *ngIf="layout==0&&mobileLogoEnable==1" fxFlex fxLayoutAlign="start center">
               <div fxFlex="75">
                <img [src]="serverPath3+mobileLogoSrc" onerror="this.src='';" style="width: 100%;"/>
                </div>
                <div fxFlex="5"></div>
                <div fxFlex="20" fxLayoutAlign="center center">
                    <button [style.color]="data.headerIconColour"  class="responsive-toggle"
                    mat-icon-button (click)="toggleSidebar()" >
                    <i style="font-size: 25px;" [style.color]="data.headerIconColour" class="material-icons ">menu</i>
                    </button>
                </div>
            </div>

        </section>

        <section *ngIf="layout==1&&mobileLogoEnable==0" fxLayout="row" fxLayoutAlign="center center">
            <calsoft-logo [sitename]="data.siteName" fxFlex fxLayoutAlign="center center"
                [type]="'light'"></calsoft-logo>
        </section>

        <div *ngIf="layout==1&&mobileLogoEnable==1" fxFlex fxLayoutAlign="center center">
            <img [src]="serverPath3+mobileLogoSrc" fxLayoutAlign="center center" onerror="this.src='';"
                style="height: 100px; width: 100%;" [ngStyle]="{'height': '150px'}" />        
        </div>

    </mat-toolbar-row>

</mat-toolbar>

<mat-toolbar style="padding:0px ; height: 60px;" fxHide fxShow.lt-md class="calsoft-fixed-header mobileheader" fxLayout="row"
    fxLayoutAlign="space-between center">
    <section fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">

        <div fxFlex="75">
            <img [src]="serverPath3+mobileLogoSrc" onerror="this.src='';" style="width: 100%;"/>
            </div>
            <div fxFlex="5"></div>
            <div fxFlex="20" fxLayoutAlign="center center">
                <button [style.color]="data.headerIconColour"  class="responsive-toggle"
                mat-icon-button (click)="toggleSidebar()" >
                <i style="font-size: 25px;" [style.color]="data.headerIconColour" class="material-icons ">menu</i>
                </button>
            </div>

    </section>
</mat-toolbar>

<!-- <div class="page-container">
    <div class="menu-content" *ngIf="contentDiv">
        <calsoft-sidebar-menu-new  [menuItems]="categoryArray" [menuParentId]="'2'"></calsoft-sidebar-menu-new>
    </div>

</div> -->
